<template>
    <v-container>
        <v-tabs centered v-model="activeTab">
            <v-tab>HEUTE
            </v-tab>
            <v-tab>ARCHIV
            </v-tab>
          <v-tab>WOCHENÜBERSICHT
          </v-tab>
        </v-tabs>
        <div v-if="activeTab===0">
            <div class="headline pa-2" v-if="!acceptingOrders || !isCurrentTimeInOpeningHours">Das Restaurant ist momentan nicht offen!</div>
        <order-overview showRestaurant today></order-overview>
        </div>
        <div v-if="activeTab===1">
            <v-row>
                <v-col cols="12" md="4" lg="3"><v-date-picker first-day-of-week="1" :max="new Date().toISOString()" full-width v-model="pickedDate" ></v-date-picker></v-col>
                <v-col cols="12" md="8" lg="9" v-if="pickedDate" ><order-overview showRestaurant :date="usableDate"></order-overview></v-col>
                <v-col cols="12" md="8" lg="9" v-else><p >Wählen sie ein Datum aus.</p></v-col>
            </v-row>
        </div>
      <div v-if="activeTab===2">
          <weekly-overview></weekly-overview>
      </div>
    </v-container>
</template>

<script>
    import OrderOverview from "@/components/OrderOverview";
    import {mapGetters} from "vuex";
    import WeeklyOverview from "@/components/WeeklyOverview";
    export default {
        name: "manage-bookings",
        components: {WeeklyOverview, OrderOverview},
        data(){
            return{
                activeTab: undefined,
                pickedDate:undefined
            }
        },
        computed: {
            ...mapGetters({
                hasCheckedLogin: 'userStore/hasCheckedLogin',
                restaurantId: 'restaurantStore/id',
                claims: 'userStore/claims',
                acceptingOrders: 'restaurantStore/acceptingOrders',
                isCurrentTimeInOpeningHours: 'restaurantStore/isCurrentTimeInOpeningHours'
            }),
            usableDate() {
                return this.pickedDate ? new Date(new Date(this.pickedDate).setHours(0, 0 ,0 ,0)): undefined
            }
        },
        watch:{
            hasCheckedLogin(value){
                if(value && (!this.claims.restaurants || !this.claims.restaurants.includes(this.restaurantId))){
                    this.$router.push({name:'index'})
                }
            }
        },
        created(){
            if(this.hasCheckedLogin && (!this.claims.restaurants || !this.claims.restaurants.includes(this.restaurantId))){
                this.$router.push({name:'index'})
            }
        }
    }
</script>

<style scoped>

</style>