<template>
  <v-row>
    <v-col cols="12" md="4" lg="3">
      <v-date-picker multiple first-day-of-week="1" @click:date="handleInput" :max="new Date().toISOString()" full-width
                     :value="pickedWeek"></v-date-picker>
      <v-btn @click="downloadPDF">DOWNLOAD</v-btn>

    </v-col>
    <v-col cols="12" md="8" lg="9" v-if="pickedWeek.length > 0 && !loading">
    <span v-if="orders({start:minDate, end:maxDate}).length > 0">
    <v-row>
      <v-col cols="12">
        <p>
          Von {{ minDate.toLocaleDateString() }} bis {{ maxDate.toLocaleDateString() }} <br>
          ---------------------------- <br>
          Anzahl Bestellungen: {{ orders({start: minDate, end: maxDate}).length }}<br>
          Summe: {{ (sumInCash + sumInPaypal) / 100 |currency }}<br>
          Davon bar bezahlt: {{ sumInCash / 100 |currency }}<br>
          Davon per Paypal: {{ sumInPaypal / 100 |currency }}<br>
          Transaktionsgebühren: {{ transactionFee / 100 |currency }}<br>
          ---------------------------- <br>
          Gesamtauszahlung: {{ (sumInPaypal - transactionFee) / 100 |currency }} <br>
        </p>
      </v-col>
    </v-row>
    <v-data-table
        disable-pagination
        hide-default-footer
        dense
        :headers="headers"
        :items="orders({start:minDate, end:maxDate})"
        item-key="name"
        class="elevation-1"
    >
      <template
          v-slot:item.timestamp="{ item }"
      >
        {{ item.timestamp.toLocaleString() }}
      </template>
      <template
          v-slot:item.id="{ item }"
      >
        {{ item.id.substring(0, 6) }}
      </template>
      <template
          v-slot:item.totalPrice="{ item }"
      >
        {{ item.totalPrice / 100 |currency }} {{ (item.paymentOption == 'paypal' ? '*' : '') }}
      </template>
    </v-data-table>
    <v-row>
      <v-col cols="12">
      <p v-if="sumInPaypal > 0">
        *Die Bestellung wurde per Paypal bezahlt und es gibt eine Transaktionsgebühr von 35 cent +2,49%
      </p>
      </v-col>
    </v-row>
      </span>
      <span v-else>
      <v-row>
        <v-col cols="12">
          <p>
            Von {{ minDate.toLocaleDateString() }} bis {{ maxDate.toLocaleDateString() }} <br>
   Es gibt keine Bestellungen in diesem Zeitraum.
          </p>
          </v-col>
        </v-row>
    </span>
    </v-col>
    <v-col cols="12" md="8" lg="9" v-else-if="loading">
      <v-layout align-center justify-center class="pt-5 pb-8">
        <v-progress-circular
            indeterminate
            size="80"
            color="primary"
        ></v-progress-circular>
      </v-layout>
    </v-col>
    <v-col cols="12" md="8" lg="9" v-else>
      <v-row>
        <v-col cols="12"><p>Wählen Sie eine Woche aus.</p></v-col>
      </v-row>
    </v-col>

  </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
  name: "WeeklyOverview",
  data() {
    return {
      pickedWeek: [],
      loading: false,
      minDate: undefined,
      maxDate: undefined,
      headers: [
        {
          text: 'Datum',
          align: 'start',
          sortable: false,
          value: 'timestamp',
        },
        {
          text: 'Bestellnummer',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Preis',
          align: 'start',
          sortable: true,
          value: 'totalPrice',
        },
      ],
    }
  },
  props: {
  },
  computed: {
    ...mapGetters({
      orders: 'orderStore/sortedRestaurantOrdersDateRange',
    }),
    sumInCash() {
      return this.orders({
        start: this.minDate,
        end: this.maxDate
      }).filter(x => x.paymentOption == 'cash').reduce(function (prev, cur) {
        return prev + cur.totalPrice
      }, 0)
    },
    sumInPaypal() {
      return this.orders({
        start: this.minDate,
        end: this.maxDate
      }).filter(x => x.paymentOption == 'paypal').reduce(function (prev, cur) {
        return prev + cur.totalPrice
      }, 0)
    },
    transactionFee() {
      return this.orders({
        start: this.minDate,
        end: this.maxDate
      }).filter(x => x.paymentOption == 'paypal').reduce(function (prev, cur) {
        return prev + Math.ceil(cur.totalPrice * (0.0249)) + 35
      }, 0)
    }
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchRestaurantOrdersByDateRange: 'orderStore/fetchRestaurantOrdersByDateRange',
    }),
    handleInput(input) {
      this.loading = true
      let date = new Date(input)
      const weekday = date.getDay()
      const array = []
      const dateObjArray = []
      let indexDate = new Date(date)
      let i
      if (weekday == 0) {
        array.push(this.createDateString(date))
        dateObjArray.push(new Date(date))
        for (i = 0; i < 6; i++) {
          indexDate = new Date(indexDate.setDate(indexDate.getDate() - 1))
          dateObjArray.push(new Date(indexDate))
          array.push(this.createDateString(indexDate))
        }
      } else {
        array.push(this.createDateString(date))
        dateObjArray.push(new Date(date))
        for (i = weekday; i <= 6; i++) {
          indexDate = new Date(indexDate.setDate(indexDate.getDate() + 1))
          array.push(this.createDateString(indexDate))
          dateObjArray.push(new Date(indexDate))
        }
        indexDate = date
        for (i = weekday; i > 1; i--) {
          indexDate = new Date(indexDate.setDate(indexDate.getDate() - 1))
          array.push(this.createDateString(indexDate))
          dateObjArray.push(new Date(indexDate))
        }
      }
      this.pickedWeek = array
      this.maxDate = new Date(Math.max.apply(null, dateObjArray));
      this.minDate = new Date(Math.min.apply(null, dateObjArray));
      this.fetchRestaurantOrdersByDateRange({start: this.minDate, end: this.maxDate}).then(() => {
        this.loading = false
      })
    },
    downloadPDF(){
      const doc = new jsPDF()
      doc.setFontSize(14);
      doc.text(15, 20, "liefer.menu Bestellungen von " + this.minDate.toLocaleDateString() +' bis ' + this.maxDate.toLocaleDateString())
      const head = [['Datum', 'Bestellnummer', 'Preis']]
      const body = []
      this.orders({start:this.minDate, end:this.maxDate}).forEach(order => {
        body.push([order.timestamp.toLocaleString(), order.id.substring(0,6), (order.totalPrice/100).toString() + ' Euro' + (order.paymentOption == 'paypal' ? '*' : '')])
      })
      doc.autoTable({ head, body, margin:{top:30} })
      const finalY = doc.lastAutoTable.finalY
      doc.setFontSize(10);
      doc.text(15, finalY+10, "*Die Bestellung wurde per Paypal bezahlt und es gibt eine Transaktionsgebühr von 35 cent +2,49%\n\n" +
          "Anzahl Bestellungen: " + this.orders({start:this.minDate, end:this.maxDate}).length.toString() + '\n' +
          'Davon bar bezahlt: ' + this.sumInCash/100 + ' Euro\n' +
          'Davon per Paypal: ' + this.sumInPaypal/100 + ' Euro\n' +
          'Summe: ' + (this.sumInCash + this.sumInPaypal) / 100 + ' Euro\n' +
          'Transaktionsgebuehren: ' + this.transactionFee/100 + ' Euro\n' +
          'Gesamtauszahlung: ' + (this.sumInPaypal - this.transactionFee)/100 + ' Euro')
      doc.save('Bestellungen-'+this.createDateString(this.minDate) + '-' +  this.createDateString(this.maxDate)+ '.pdf')

    },
    createDateString(date) {
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = '0' + dt
      }
      if (month < 10) {
        month = '0' + month
      }

      return (year + '-' + month + '-' + dt)
    }
  },
}
</script>

<style scoped>

</style>